


















import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

export default defineComponent({
  name: "UnblockUserModal",
  props: {
    cbOnUnblock: {
      type: Function,
      default: () => 1,
    },
  },
  methods: {
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    yesCb() {
      (this as { cbOnUnblock: () => void }).cbOnUnblock?.();
      this.closeModal();
    },
  },
});
